 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M1915 2725 c-24 -23 -25 -29 -25 -155 0 -126 1 -132 25 -155 15 -16
36 -25 55 -25 19 0 40 9 55 25 24 23 25 29 25 159 0 133 0 135 -26 155 -36 28
-79 27 -109 -4z m80 -155 l0 -125 -25 0 -25 0 -3 114 c-1 63 0 121 3 129 3 7
15 12 27 10 22 -3 23 -6 23 -128z"/>
<path d="M2080 2742 c0 -5 16 -61 35 -125 22 -73 35 -136 35 -171 0 -53 1 -56
25 -56 24 0 25 3 25 59 0 49 23 151 64 279 7 21 5 23 -20 20 -22 -2 -30 -10
-37 -38 -22 -91 -29 -112 -34 -107 -2 3 -13 36 -22 74 -15 61 -20 68 -44 71
-15 2 -27 -1 -27 -6z"/>
<path d="M2290 2570 l0 -180 30 0 30 0 0 180 0 180 -30 0 -30 0 0 -180z"/>
<path d="M2387 2743 c-13 -26 0 -38 46 -41 26 -2 47 -7 47 -11 0 -4 -22 -63
-50 -131 -27 -68 -50 -134 -50 -147 0 -22 3 -23 80 -23 79 0 80 0 80 25 0 23
-3 25 -51 25 -59 0 -60 -10 7 148 24 57 44 117 44 133 l0 29 -74 0 c-41 0 -77
-3 -79 -7z"/>
<path d="M2616 2733 c-3 -10 -10 -54 -16 -98 -6 -44 -15 -102 -20 -130 -21
-124 -22 -115 4 -115 19 0 26 7 31 30 6 26 11 30 41 30 30 0 35 -4 41 -30 4
-23 11 -30 28 -30 18 0 24 7 27 29 2 17 1 28 -4 25 -4 -3 -8 7 -8 23 0 15 -9
82 -20 148 -11 66 -20 123 -20 128 0 4 -18 7 -39 7 -29 0 -41 -5 -45 -17z m59
-175 l7 -58 -27 0 c-26 0 -27 2 -22 38 3 20 9 62 13 92 8 54 8 54 15 20 4 -19
11 -61 14 -92z"/>
<path d="M3078 2733 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1279 2673 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M1289 2523 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1339 2483 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1524 2478 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M1199 2423 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1601 2407 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M1549 2403 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1449 2393 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1249 2323 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
<path d="M1189 2313 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
<path d="M1490 2331 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M1917 2332 c6 -4 13 -32 16 -62 l3 -55 2 57 c2 44 6 58 20 61 9 3 1
5 -18 6 -19 0 -30 -3 -23 -7z"/>
<path d="M1993 2280 c0 -45 2 -52 4 -22 5 54 26 51 34 -4 l6 -39 2 43 c1 36
-3 45 -18 49 -11 3 -22 12 -24 22 -3 9 -4 -13 -4 -49z"/>
<path d="M2606 2324 c-3 -9 -5 -37 -4 -62 l1 -47 4 40 c3 22 10 43 16 47 9 6
8 8 -2 8 -11 0 -11 3 -1 15 7 9 8 15 2 15 -5 0 -13 -7 -16 -16z"/>
<path d="M2753 2280 c0 -52 1 -57 6 -26 8 55 29 58 34 4 l4 -43 1 48 c2 40 -1
47 -17 47 -11 0 -22 8 -24 18 -3 9 -5 -12 -4 -48z"/>
<path d="M3120 2325 c0 -8 -8 -15 -19 -15 -10 0 -21 -9 -24 -20 -3 -11 -1 -20
4 -20 5 0 9 7 9 16 0 9 6 14 13 12 15 -6 16 -60 0 -65 -6 -2 -13 4 -16 14 -4
14 -5 13 -6 -4 -1 -18 4 -23 24 -23 24 0 25 3 25 60 0 33 -2 60 -5 60 -3 0 -5
-7 -5 -15z"/>
<path d="M1314 2298 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M3221 2287 c1 -21 3 -25 6 -12 3 11 10 23 15 26 5 3 3 9 -6 12 -13 5
-16 0 -15 -26z"/>
<path d="M2064 2297 c-10 -28 0 -72 19 -75 9 -2 17 1 17 7 0 6 -7 8 -15 5 -9
-4 -15 0 -15 10 0 10 8 16 21 16 15 0 20 5 17 22 -4 26 -35 37 -44 15z m36
-12 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z"/>
<path d="M2160 2268 c0 -49 5 -47 14 5 4 23 3 37 -4 37 -5 0 -10 -19 -10 -42z"/>
<path d="M2196 2295 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M2240 2295 c-7 -9 -9 -26 -5 -45 5 -25 11 -30 28 -28 24 3 32 26 23
64 -7 27 -27 31 -46 9z m36 -19 c10 -39 -17 -65 -30 -30 -8 20 2 54 14 54 6 0
12 -11 16 -24z"/>
<path d="M2312 2263 l1 -48 4 43 c5 54 25 51 34 -4 l7 -39 -2 45 c-1 39 -4 45
-23 48 -21 3 -23 0 -21 -45z"/>
<path d="M2377 2303 c6 -4 13 -26 16 -48 4 -40 4 -40 3 4 -1 30 -6 46 -16 48
-10 3 -11 2 -3 -4z"/>
<path d="M2427 2304 c-4 -4 -7 -22 -7 -41 0 -36 23 -54 42 -35 8 8 5 9 -11 5
-15 -4 -21 -1 -21 11 0 10 8 16 21 16 15 0 20 5 17 22 -3 23 -27 35 -41 22z
m33 -19 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15
-15z"/>
<path d="M2537 2303 c-4 -3 -7 -11 -7 -17 0 -6 5 -4 10 4 13 21 33 0 28 -31
-4 -29 -28 -38 -28 -10 0 12 -4 21 -9 21 -4 0 -6 -11 -3 -25 7 -41 52 -24 52
20 0 31 -27 55 -43 38z"/>
<path d="M2684 2297 c-11 -31 0 -72 20 -72 11 0 20 6 22 13 1 7 0 8 -3 2 -12
-25 -33 -9 -33 25 0 34 21 50 33 25 3 -6 4 -5 3 2 -4 19 -35 22 -42 5z"/>
<path d="M2832 2301 c-8 -5 -12 -22 -10 -42 2 -26 8 -35 26 -37 12 -2 22 1 22
6 0 5 -7 9 -16 8 -9 0 -19 5 -21 12 -3 7 4 12 17 12 16 0 21 5 18 22 -3 24
-17 32 -36 19z m28 -16 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8
23 -8z"/>
<path d="M2905 2300 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 4 -9 1 -16 -6 -16 -19
0 -36 -21 -30 -36 3 -8 16 -14 29 -14 25 0 30 20 18 68 -6 23 -28 30 -39 12z
m33 -52 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z"/>
<path d="M2971 2243 c1 -37 4 -58 6 -47 2 12 13 24 24 27 17 5 20 12 17 44 -2
31 -7 39 -25 41 -22 3 -23 1 -22 -65z m39 22 c0 -24 -5 -35 -15 -35 -10 0 -15
11 -15 35 0 24 5 35 15 35 10 0 15 -11 15 -35z"/>
<path d="M3155 2300 c-4 -6 -4 -14 -1 -17 3 -4 6 -1 6 5 0 7 7 12 15 12 22 0
18 -27 -5 -33 -12 -3 -20 -14 -20 -26 0 -16 6 -21 26 -21 23 0 25 3 22 42 -3
43 -27 64 -43 38z m35 -55 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15
15 15 8 0 15 -7 15 -15z"/>
<path d="M3274 2298 c-5 -7 -3 -8 6 -3 7 4 16 2 20 -6 6 -8 1 -16 -13 -24 -31
-15 -28 -39 6 -43 24 -3 27 0 27 27 0 16 -3 36 -6 45 -7 19 -30 21 -40 4z m36
-47 c0 -14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z"/>
<path d="M1049 2253 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2200 2245 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M3226 2235 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"/>
<path d="M997 2229 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M1228 2163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1029 2133 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
</g>
</svg>



    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}